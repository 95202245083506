// Third-party imports.
import React from "react";
import jQuery from "jquery";
import PropTypes from "prop-types";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// Our imports.
import LegalTermsAgreement from "../legal_terms_agreement";
import EuCitizenCheckbox from "./eu_citizen_checkbox";
import ButtonWithSpinner from "../button_with_spinner";
import { Provider as StyletronProvider } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import { BaseProvider } from "baseui";
import { ThemeProvider } from "styled-components";
import deprecatedTheme from "../styling/deprecated_theme";
import baseuiTheme from "../styling/baseui_theme";
import "./after_push_through_to_match_flow.css";
import ProgramSchoolLogoBlock from "../shared/login_and_sign_up/program_school_logo_block";

const engine = new Styletron();
class AfterPushThroughToMatchFlow extends React.Component {
  static propTypes = {
    // The jwt token containing the user's email address.
    token: PropTypes.string.isRequired,
    programData: PropTypes.object.isRequired,

    createZendeskTicketPath: PropTypes.string.isRequired,
    signUpAndRegisterPath: PropTypes.string.isRequired,
    csrfToken: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      // The user's phone number in E164 format.
      phone: "",

      isEUCitizen: false,
      acceptedLegalTerms: false,

      // We may display errors to the user if information they provided is invalid.
      errors: [],

      // Whether a request to the backend is in-flight.
      processingSubmission: false,
    };
  }

  render() {
    return (
      <StyletronProvider value={engine}>
        <BaseProvider theme={baseuiTheme}>
          <ThemeProvider theme={{ ...deprecatedTheme, ...baseuiTheme }}>
            <div className="brand-name text-align-center">
              <ProgramSchoolLogoBlock
                programLogo={this.props.programData.logo}
                programName={this.props.programData.title}
                schoolLogo={this.props.programData.schoolLogo}
                schoolName={this.props.programData.schoolName}
              />
            </div>
            <div className="after-push-through-to-match-flow text-align-center">
              {this.renderFormElement()}
              {this.renderFormErrorsElement()}

              <div className="vertical-spacing-top three-rems">
                <span className="regular-alt">What's the point of this?</span>
                {this.renderWhatIsThePointOfThisText()}
              </div>
            </div>
          </ThemeProvider>
        </BaseProvider>
      </StyletronProvider>
    );
  }

  renderFormElement() {
    // Default to US as that's where most of our users are based.
    const defaultCountryCode = "US";

    // We aren't using the submit capabilities of the <form> element -- that is handled by Ajax.
    return (
      <form className="vertical-spacing four-rems" onSubmit={this.onSubmitForm}>
        <ul className="form-items-container">
          <li className="form-item">
            <span className="text-align-center">
              <PhoneInput
                aria-label="phone"
                country={defaultCountryCode}
                defaultCountry="US"
                placeholder="Phone number"
                value={this.state.phone}
                name="phone"
                onChange={(value) => this.onFormDataChanged("phone", value)}
              />
            </span>
          </li>
        </ul>
        <span className="text-align-center">
          <div className="vertical-spacing-top one-rem">
            <LegalTermsAgreement
              acceptedLegalTerms={this.state.acceptedLegalTerms}
              onAgreementStatusChanged={(agreementStatus) =>
                this.setState({ acceptedLegalTerms: agreementStatus })
              }
            />
            <EuCitizenCheckbox
              schoolId={this.props.programData.schoolId}
              euCitizen={this.state.isEUCitizen}
              onChange={(event) => this.onFormDataChanged("isEUCitizen", event.target.checked)}
            />
          </div>
        </span>
        <div className="vertical-spacing-top one-rem">
          <ButtonWithSpinner
            text={"Continue"}
            isDisabled={!this.allowSubmit()}
            isSpinning={this.state.processingSubmission}
          />
        </div>
      </form>
    );
  }

  allowSubmit() {
    return this.state.acceptedLegalTerms;
  }

  onFormDataChanged(key, newValue) {
    // Copied from SignUpFlow.

    // Whenever form data changes, we want to reset the errors attribute as they may no longer be
    // relevant. (There might still be errors, of course, but we want to be optimistic. We'll
    // discover them again when the user presses submit.)
    const update = { errors: [] };
    update[key] = newValue;

    this.setState(update);
  }

  renderFormErrorsElement() {
    if (this.state.errors.length === 0) {
      return null;
    }

    const errorItems = this.state.errors.map((error, i) => (
      <div key={i} className="error-item">
        {error}
      </div>
    ));

    return <div className="form-errors">{errorItems}</div>;
  }

  onSubmitForm = async (event) => {
    event.preventDefault();

    this.setState({ processingSubmission: true });
    try {
      await jQuery.post({
        url: this.props.signUpAndRegisterPath,
        headers: { "X-CSRF-Token": this.props.csrfToken },
        data: {
          program_id: this.props.programData.id,
          token: this.props.token,
          phone: this.state.phone,
          is_eu_citizen: this.state.isEUCitizen,
          has_accepted_privacy_policy: this.state.acceptedLegalTerms,
        },
      });

      // Move the user along to the rest of the webapp.
      window.location.reload();
    } catch (response) {
      // Display the error in the form.
      if (response.responseJSON) {
        this.setState({ errors: response.responseJSON.errors });
      } else {
        this.setState({
          errors: [`Error: HTTP ${response.status}. Please try again or refresh the page.`],
        });
      }
      return;
    } finally {
      this.setState({ processingSubmission: false });
    }
  };

  renderWhatIsThePointOfThisText() {
    return (
      <p>
        Your phone number will be shared with your mentor when you are matched (and in exchange, you
        will receive theirs). We will also use it to check in with you and make sure everything is
        going ok in the program.
      </p>
    );
  }
}

export default AfterPushThroughToMatchFlow;
